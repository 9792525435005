<template>
  <div class="relative h-full min-h-screen">
    <div class="pt-10 px-10 flex justify-center align-middle">
      <img :width=50 src="./assets/eye.png" />
      <span class="pl-4 text-3xl font-serif pt-2">Flamingo Party</span>
    </div>

    <div class="p-10">
      <router-view />
    </div>

    <div class="pt-10"></div>
  </div>
</template>

<script>
import TheNavBar from '@/components/TheNavBar';
import TheFooter from '@/components/gem-farm/TheFooter';
import TheCat from '@/components/gem-farm/TheCat';
export default {
  components: { TheCat, TheFooter, TheNavBar },
};
</script>

<style>
input[type='radio']:checked + span {
  @apply text-black;
}

body, html {
  background: #eee;
}
</style>
